import React from "react"
import styles from "./LegalScreens.module.css"
import { Helmet } from "react-helmet"
/**
 * Show privacy policy
 */

// old one to be deleted-----=== START HERE
// export default class privacyPolicyScreen extends React.Component {
//   state = {
//     numPages: 0,
//   }

//   onDocumentLoadSuccess = (data: { numPages: Number }) => {
//     this.setState({ numPages: data.numPages })
//   }

//   render() {
//     const { numPages } = this.state

//     return (
//       <main className={styles.main}>
//         <Helmet>
//           <title>Best Goal Tracking, Life Coaching, and Goal Sharing App</title>
//           <meta
//             name='description'
//             content='Set goals the right way. Share with friends. Get success strategies from the world’s best life coaches. Our digital life coach and goal planner provide guidance to help you to create and crush all your goals. Download GoalMogul today.'
//           />
//           <meta
//             name='keywords'
//             content='best goal tracking apps, daily goal tracker, online goal tracker, certified life coach training online, personal goal tracker'
//           />
//           <link rel='canonical' href='https://www.goalmogul.com/privacy' />
//         </Helmet>
//         <h1>Privacy Policy</h1>
//         <p>Effective date: September 8, 2020</p>
//         <p>
//           GoalMogul ("us", "we", or "our") operates the GoalMogul mobile
//           application (the "Service").
//         </p>
//         <p>
//           This page informs you of our policies regarding the collection, use,
//           and disclosure of personal data when you use our Service and the
//           choices you have associated with that data. Our Privacy Policy for
//           GoalMogul is managed through{" "}
//           <a href='https://www.freeprivacypolicy.com/free-privacy-policy-generator.php'>
//             Free Privacy Policy.
//           </a>
//         </p>
//         <p>
//           We use your data to provide and improve the Service. By using the
//           Service, you agree to the collection and use of information in
//           accordance with this policy. Unless otherwise defined in this Privacy
//           Policy, terms used in this Privacy Policy have the same meanings as in
//           our Terms and Conditions.
//         </p>
//         <h2>Information Collection And Use</h2>
//         <p>
//           We collect several different types of information for various purposes
//           to provide and improve our Service to you.
//         </p>
//         <h3>Types of Data Collected</h3>
//         <h4>Personal Data</h4>
//         <p>
//           While using our Service, we may ask you to provide us with certain
//           personally identifiable information that can be used to contact or
//           identify you ("Personal Data"). Personally identifiable information
//           may include, but is not limited to:
//         </p>
//         <ul>
//           <li>Email address</li>
//           <li>First name and last name</li>
//           <li>Phone number</li>
//           <li>Cookies and Usage Data</li>
//         </ul>
//         <h4>Usage Data</h4>
//         <p>
//           When you access the Service by or through a mobile device, we may
//           collect certain information automatically, including, but not limited
//           to, the type of mobile device you use, your mobile device unique ID,
//           the IP address of your mobile device, your mobile operating system,
//           the type of mobile Internet browser you use, unique device identifiers
//           and other diagnostic data ("Usage Data").
//         </p>
//         <h4>Tracking & Cookies Data</h4>
//         <p>
//           We use cookies and similar tracking technologies to track the activity
//           on our Service and hold certain information.
//         </p>
//         <p>
//           Cookies are files with small amount of data which may include an
//           anonymous unique identifier. Cookies are sent to your browser from a
//           website and stored on your device. Tracking technologies also used are
//           beacons, tags, and scripts to collect and track information and to
//           improve and analyze our Service.
//         </p>
//         <p>
//           You can instruct your browser to refuse all cookies or to indicate
//           when a cookie is being sent. However, if you do not accept cookies,
//           you may not be able to use some portions of our Service.
//         </p>
//         <p>Examples of Cookies we use:</p>
//         <ul>
//           <li>
//             <b>Session Cookies.</b> We use Session Cookies to operate our
//             Service.
//           </li>
//           <li>
//             <b>Preference Cookies.</b> We use Preference Cookies to remember
//             your preferences and various settings.
//           </li>
//           <li>
//             <b>Security Cookies.</b> We use Security Cookies for security
//             purposes.
//           </li>
//         </ul>
//         <h4>Phone Contacts Data</h4>
//         <p>
//           We receive personal data (including contact information) about you and
//           your contacts when you or others import or sync their contacts with
//           our Services, associate their contacts with Member profiles, or send
//           messages using our Services (including invites or connection
//           requests).
//         </p>
//         <h3>Use of Data</h3>
//         <p>GoalMogul uses the collected data for various purposes:</p>
//         <ul>
//           <li>To provide and maintain the Service</li>
//           <li>To notify you about changes to our Service</li>
//           <li>
//             To allow you to participate in interactive features of our Service
//             when you choose to do so
//           </li>
//           <li>To provide customer care and support</li>
//           <li>
//             To provide analysis or valuable information so that we can improve
//             the Service
//           </li>
//           <li>To monitor the usage of the Service</li>
//           <li>To detect, prevent and address technical issues</li>
//           <li>
//             To suggest other users who you may already know to be added as
//             friends
//           </li>
//         </ul>
//         <h2>Transfer Of Data</h2>
//         <p>
//           Your information, including Personal Data, may be transferred to — and
//           maintained on — computers located outside of your state, province,
//           country or other governmental jurisdiction where the data protection
//           laws may differ than those from your jurisdiction.
//         </p>
//         <p>
//           If you are located outside United States and choose to provide
//           information to us, please note that we transfer the data, including
//           Personal Data, to United States and process it there.
//         </p>
//         <p>
//           Your consent to this Privacy Policy followed by your submission of
//           such information represents your agreement to that transfer.
//         </p>
//         <p>
//           GoalMogul will take all steps reasonably necessary to ensure that your
//           data is treated securely and in accordance with this Privacy Policy
//           and no transfer of your Personal Data will take place to an
//           organization or a country unless there are adequate controls in place
//           including the security of your data and other personal information.
//         </p>
//         <h2>Disclosure Of Data</h2>
//         <h3>Legal Requirements</h3>
//         <p>
//           GoalMogul may disclose your Personal Data in the good faith belief
//           that such action is necessary to:
//         </p>
//         <ul>
//           <li>To comply with a legal obligation</li>
//           <li>To protect and defend the rights or property of GoalMogul</li>
//           <li>
//             To prevent or investigate possible wrongdoing in connection with the
//             Service
//           </li>
//           <li>
//             To protect the personal safety of users of the Service or the public
//           </li>
//           <li>To protect against legal liability</li>
//         </ul>
//         <h2>Security Of Data</h2>
//         <p>
//           The security of your data is important to us, but remember that no
//           method of transmission over the Internet, or method of electronic
//           storage is 100% secure. While we strive to use commercially acceptable
//           means to protect your Personal Data, we cannot guarantee its absolute
//           security.
//         </p>
//         <h2>Service Providers</h2>
//         <p>
//           We may employ third party companies and individuals to facilitate our
//           Service ("Service Providers"), to provide the Service on our behalf,
//           to perform Service-related services or to assist us in analyzing how
//           our Service is used.
//         </p>
//         <p>
//           These third parties have access to your Personal Data only to perform
//           these tasks on our behalf and are obligated not to disclose or use it
//           for any other purpose.
//         </p>
//         <h3>Analytics</h3>
//         <p>
//           We may use third-party Service Providers to monitor and analyze the
//           use of our Service.
//         </p>
//         <ul>
//           <li>
//             <b>Google Analytics</b>
//             <ul className={styles.noBullet}>
//               <li>
//                 Google Analytics is a web analytics service offered by Google
//                 that tracks and reports website traffic. Google uses the data
//                 collected to track and monitor the use of our Service. This data
//                 is shared with other Google services. Google may use the
//                 collected data to contextualize and personalize the ads of its
//                 own advertising network.
//               </li>
//               <li>
//                 You may opt-out of certain Google Analytics features through
//                 your mobile device settings, such as your device advertising
//                 settings or by following the instructions provided by Google in
//                 their Privacy Policy:{" "}
//                 <a href='https://policies.google.com/privacy?hl=en'>
//                   https://policies.google.com/privacy?hl=en
//                 </a>
//               </li>
//               <li>
//                 For more information on the privacy practices of Google, please
//                 visit the Google Privacy & Terms web page:{" "}
//                 <a href='https://policies.google.com/privacy?hl=en'>
//                   https://policies.google.com/privacy?hl=en
//                 </a>
//               </li>
//             </ul>
//           </li>
//         </ul>
//         <h2>Links To Other Sites</h2>
//         <p>
//           Our Service may contain links to other sites that are not operated by
//           us. If you click on a third party link, you will be directed to that
//           third party's site. We strongly advise you to review the Privacy
//           Policy of every site you visit.
//         </p>
//         <p>
//           We have no control over and assume no responsibility for the content,
//           privacy policies or practices of any third party sites or services.
//         </p>
//         <h2>Children's Privacy</h2>
//         <p>
//           Our Service does not address anyone under the age of 18 ("Children").
//         </p>
//         <p>
//           We do not knowingly collect personally identifiable information from
//           anyone under the age of 18. If you are a parent or guardian and you
//           are aware that your Children has provided us with Personal Data,
//           please contact us. If we become aware that we have collected Personal
//           Data from children without verification of parental consent, we take
//           steps to remove that information from our servers.
//         </p>
//         <h2>Changes To This Privacy Policy</h2>
//         <p>
//           We may update our Privacy Policy from time to time. We will notify you
//           of any changes by posting the new Privacy Policy on this page.
//         </p>
//         <p>
//           We will let you know via email and/or a prominent notice on our
//           Service, prior to the change becoming effective and update the
//           "effective date" at the top of this Privacy Policy.
//         </p>
//         <p>
//           You are advised to review this Privacy Policy periodically for any
//           changes. Changes to this Privacy Policy are effective when they are
//           posted on this page.
//         </p>
//         <h2>Contact Us</h2>
//         <p>
//           If you have any questions about this Privacy Policy, please contact
//           us:
//         </p>
//         <ul>
//           <li>By email: dev@goalmogul.com</li>
//         </ul>
//       </main>
//     )
//   }
// }
//old one to be deletes  == ends her

export default class privacyPolicyScreen extends React.Component {
  state = {
    numPages: 0,
  }

  onDocumentLoadSuccess = (data: { numPages: Number }) => {
    this.setState({ numPages: data.numPages })
  }

  render() {
    const { numPages } = this.state

    return (
      <main className={styles.main}>
        <div className={styles.PrivacyMainDiv}>
          <Helmet>
            <title>
              Best Goal Tracking, Life Coaching, and Goal Sharing App
            </title>
            <meta
              name='description'
              content='Set goals the right way. Share with friends. Get success strategies from the world’s best life coaches. Our digital life coach and goal planner provide guidance to help you to create and crush all your goals. Download GoalMogul today.'
            />
            <meta
              name='keywords'
              content='best goal tracking apps, daily goal tracker, online goal tracker, certified life coach training online, personal goal tracker'
            />
            <link rel='canonical' href='https://www.goalmogul.com/privacy' />
          </Helmet>
          <h1 className={styles.mainH1}>Privacy Policy</h1>
          <p className={styles.mainP}>Effective Date: December 5, 2022</p>
          <p className={styles.mainp2}>
            GoalMogul ("us", "we", or "our") operates the GoalMogul mobile
            application (the "Service"). This page informs you of our policies
            regarding the collection, use, and disclosure of personal data when
            you use our Service and the choices you have associated with that
            data.
          </p>
          <p className={styles.mainP4}>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </p>
          {/* <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions.
        </p> */}
          <h2 className={styles.mainH1}>Introduction</h2>
          <p className={styles.mainp2}>
            We are a social network and online platform for professionals and
            development, self-help, coaching and law of attraction enthusiasts.
            People use our Service in the spirit of collaborative achievement to
            support, encourage, assist, help each other achieve their shared
            goals, dreams, and aspirations. Our Privacy Policy applies to any
            Member or User of our Service.
          </p>
          <p className={styles.mainP4}>
            Our registered users (“Members”) share their identities; engage with
            their network; exchange ‘Goals’, ‘Steps and Needs’ (steps they ought
            to take when pursuing their goals things or things they would like
            support or help with in order to achieve their goals), advice,
            suggestions, knowledge and insights; post and view relevant content;
            search for others; get Goal ‘Reminders’ (which can be toggled on/off
            in ‘Settings’ in the form of SMS, Email, Push Notification, or
            in-app Chatbots); choose to form ‘Accountability’ relationships
            (where Members schedule appointments with one another to hold each
            other to account on their path to achievement); get Recommended
            Steps and Needs to help with their Goals from our Services of
            Members; actively seek out or be sought out for opportunities to
            collaboratively achieve Goals. Content and data on some of our
            Services may be viewable to non-members (“Visitors”) or when Members
            share publicly posted content and data onto other social networking
            platforms.
          </p>
          <h2 className={styles.mainH1}>Services</h2>
          <p className={styles.mainp2}>
            This Privacy Policy, including our Cookie Policy applies to your use
            of our Services. This Privacy Policy applies to GoalMogul.com and
            GoalMogul-branded software or apps, but excluding services that
            state that they are offered under a different privacy policy.
          </p>
          <p className={styles.mainP4}>
            As a Member of our Services, the collection, use and sharing of your
            personal data is subject to this Privacy Policy and other documents
            referenced in this Privacy Policy, as well as updates.
          </p>
          <h2 className={styles.mainH1}>Changes</h2>
          <p className={styles.mainp2}>
            Changes to the Privacy Policy apply to your use of our Services
            after the “effective date.”
          </p>
          <p className={styles.mainP4}>
            GoalMogul can modify this Privacy Policy, and if we make material
            changes to it, we will provide notice to Members through our
            Services, or by email, to provide you the opportunity to review the
            changes before they become effective. If you object to any changes,
            you may close your account.
          </p>
          <p className={styles.mainP4}>
            You acknowledge that your continued use of our Services after we
            publish or send a notice about our changes to this Privacy Policy
            means that the collection, use and sharing of your personal data is
            subject to the updated Privacy Policy, as of its effective date
          </p>
          <h2 className={styles.mainH1}>Data We Collect</h2>
          <p className={styles.mainp2}>
            You provide data to create an account with us:
          </p>
          <h2 className={styles.mainH1}>Registration</h2>
          <p className={styles.mainp2}>
            To create an account you need to provide data including your name,
            email address, date of birth, and/or mobile number, and a password.
          </p>
          <h2 className={styles.mainH1}>Profile</h2>
          <p className={styles.mainp2}>
            You have choices about the information on your profile, such as your
            first name, last name, country, city, occupation, website links,
            work experience, skills, photo, and other details about you. Some
            Members may choose to import their LinkedIn or Clubhouse profiles.
            You don’t have to provide additional information on your profile;
            however, profile information helps you to get more from our
            Services, including building credibility, and networking with
            others. It’s your choice whether to include sensitive information on
            your profile and to make that sensitive information public. Please
            do not post or add personal data to your profile that you would not
            want to be publicly available.
          </p>
          <h2 className={styles.mainH1}>Contact/Address Book and Calendar</h2>
          <p className={styles.mainp2}>
            You may choose to give other data to us, such as by syncing your
            address book or calendar. Syncing your address book will allow us to
            suggest friends or connections on GoalMogul that are already in your
            contacts or address book. If you sync your contacts
          </p>
          <p className={styles.mainP4}>
            with our Services, we will collect your address book and calendar
            meeting information to keep growing your network by suggesting
            connections for you and others, and by providing information about
            events, e.g. times, places, attendees and contacts. Syncing your
            calendar will allow us to suggest your available time slots for
            scheduling accountability sessions with ‘accountability buddies’ you
            might meet using our Service. It will also allow you to add
            ‘GoalMogul Reminders’ that you create using our Service to your
            Calendar.
          </p>
          <h2 className={styles.mainH1}>Posting and Uploading</h2>
          <p className={styles.mainp2}>
            We collect personal data from you when you provide, post or upload
            content to our Services, or when you fill out a form, respond to a
            survey, or submit a resume or fill out a job application on our
            Services. If you opt to import your address book, we receive your
            contacts (including contact information your service provider(s) or
            app automatically added to your address book when you communicated
            with addresses or numbers not already in your list).
          </p>
          <p className={styles.mainP4}>
            You don’t have to post or upload personal data; though if you don’t,
            it may limit your ability to grow and engage with your network over
            our Services.
          </p>
          <h2 className={styles.mainH1}>Data From Others</h2>
          <p className={styles.mainp2}>Others may post or write about you.</p>
          <h2 className={styles.mainH1}>Content</h2>
          <p className={styles.mainp2}>
            You and others may post content that includes information about you
            (as part of goals, needs, posts, comments, videos, images,
            voiceclips) on our Services. We also may collect public information
            about you, such as professional-related updates/news and
            accomplishments, and make it available as part of our Services,
            including, as permitted by your choices, in notifications to others
            of mentions in the live feeds.
          </p>
          <h2 className={styles.mainH1}>Contact and Calendar Information</h2>
          <p className={styles.mainp2}>
            Others may sync their contacts or calendar with our Services.
          </p>
          <p className={styles.mainP4}>
            We receive personal data (including contact information) about you
            when others import or sync their contacts or calendar with our
            Services, associate their contacts with Member profiles, scan and
            upload business cards, or send messages using our Services
            (including invites or connection requests). If you or others opt-in
            to sync email accounts with our Services, we will also collect
            “email header” information that we can associate with Member
            profiles
          </p>
          <p className={styles.mainP4}>
            Customers and partners may provide data to us.
          </p>
          <h2 className={styles.mainH1}>Partners</h2>
          <p className={styles.mainp2}>
            We may receive personal data (e.g., your name and email address)
            about you when you use the services of our marketing and promotional
            partners.
          </p>
          <h2 className={styles.mainH1}>Service Use</h2>
          <p className={styles.mainp2}>
            We log your visits and use of our Services, including our mobile
            app.
          </p>
          <p className={styles.mainP4}>
            We log usage data when you visit or otherwise use our Services, such
            as when you view or post content (e.g., a goal, need, image, or
            video), perform a search, install or update one of our mobile apps,
            share weblinks or reply on other posts. We use log-ins, cookies,
            device information and internet protocol (“IP”) addresses to
            identify you and log your use.
          </p>
          <h2 className={styles.mainH1}>Cookies and Similar Technologies</h2>
          <p className={styles.mainp2}>
            We collect data through cookies and similar technologies.
          </p>
          <p className={styles.mainP4}>
            As further described in our Cookie Policy, we use cookies and
            similar technologies (e.g., pixels and ad tags) to collect data
            (e.g., device IDs) to recognize you and your device(s) on, off and
            across different services and devices where you have engaged with
            our Services. We also allow some others to use cookies as described
            in our Cookie Policy. If you are outside the Designated Countries,
            we also collect (or rely on others who collect) information about
            your device where you have not engaged with our Services (e.g., ad
            ID, IP address, operating system and browser information) so we can
            provide our Members with relevant ads and better understand their
            effectiveness. Learn more. You can opt out from our use of data from
            cookies and similar technologies that track your behavior on the
            sites of others for ad targeting and other ad-related purposes. For
            Visitors, the controls are here.
          </p>
          <h2 className={styles.mainH1}>Your Device and Location</h2>
          <p className={styles.mainp2}>
            We receive data through cookies and similar technologies
          </p>
          <p className={styles.mainP4}>
            When you visit or leave our Services (including some plugins and our
            cookies or similar technology on the sites of others), we receive
            the URL of both the site you came from and the one you go to and the
            time of your visit. We also get information about your network and
            device (e.g., IP address, proxy server, operating system, web
            browser and add-ons, device identifier and features, cookie IDs
            and/or ISP, or your mobile carrier). If you use our Services from a
            mobile device, that device will send us data about your location
            based on your phone settings. We will ask you to opt-in before we
            use GPS or other tools to identify your precise location.
          </p>
          <h2 className={styles.mainH1}> Messages</h2>
          <p className={styles.mainp2}>
            If you communicate through our Services, we learn about that.
          </p>
          <p className={styles.mainP4}>
            We collect information about you when you send, receive, or engage
            with messages in connection with our Services. For example, if you
            get a GoalMogul connection request, we track whether you have acted
            on it and will send you reminders.
          </p>
          <h2 className={styles.mainH1}>How We Use Your Data</h2>
          <p className={styles.mainp2}>
            We use your data to provide, support, personalize and develop our
            Services. How we use your personal data will depend on which
            Services you use, how you use those Services and the choices you
            make in your settings. We use the data that we have about you to
            provide and personalize our Services, including with the help of
            automated systems and inferences we make, so that our Services
            (including ads) can be more relevant and useful to you and others.
          </p>
          <h2 className={styles.mainH1}>Services</h2>
          <p className={styles.mainp2}>
            Our Services help you connect with others, find and be found for
            collaborative achievement opportunities, give and get help for
            achieving your goals and sharing resources, and be more productive.
          </p>
          <p className={styles.mainP4}>
            We use your data to authorize access to our Services and honor your
            settings.
          </p>
          <h2 className={styles.mainH1}>Stay Connected</h2>
          <p className={styles.mainp2}>
            Our Services allow you to stay in touch and up to date with
            colleagues, partners, clients, and other contacts. To do so, you can
            become “Friends” with the people who you choose, and who also wish
            to become “Friends” with you. When you connect with other Members,
            you will be able to search each other's connections in order to
            exchange professional opportunities.
          </p>
          <p className={styles.mainP4}>
            We use data about you (such as your profile, Goals, Steps and Needs
            you posted, Tribes and Chat groups you’ve joined, posts you’ve
            commented on or replied to, profiles you have viewed, or data
            provided through address book uploads or partner integrations) to
            help others find your profile, suggest connections for you and
            others (e.g., Members who share your contacts or similar Goals,
            Steps and Needs) and enable you to invite others to become a Member
            and connect with you. You can also opt-in to allow us to use your
            precise location or proximity to others for certain tasks (e.g. to
            suggest other nearby Members for you to connect with.)
          </p>
          <p className={styles.mainP4}>
            It is your choice whether to invite someone to our Services, send an
            “Invite” request, or allow another Member to become your “Friend”
            connection. When you invite someone to connect with you, your
            invitation will include your network and basic profile information
            (e.g., name, profile photo, publicly listed Goals, Steps, Needs, and
            public profile information). We may send invitation reminders to the
            people you invited.
          </p>
          <h2 className={styles.mainH1}>Stay Informed</h2>
          <p className={styles.mainp2}>
            Our Services allow you to stay informed about news, goals and posts
            regarding personal development, goal-setting, and achievement topics
            you care about, and from 1st and 2nd degree “Friends” in your
            network. We may use the data we have about you (e.g., data you
            provide, data we collect from your engagement with our Services and
            inferences we make from the data we have about you), to personalize
            our Services for you, such as by recommending or ranking relevant
            content (such as suggested people, posts, Tribes and Chat groups to
            join; Steps and Needs you might consider to achieve your goals;
            ‘Smart’ replies [suggested comments or replies based on the content
            of messages being sent to you in private Chat or content that you
            see on feed posts, e.g., suggested potential ways to ‘paraphrase’
            the words you are trying to send as replies\comments or messages in
            chat conversations to other Members]) and other conversations on our
            Services.
          </p>
          <p className={styles.mainP4}>
            We also use the data we have about you to suggest Goals, Steps, and
            Needs you could add to your profile that you might want to consider
            when pursuing your personal growth. So, if you let us know that you
            are interested in a new topic (e.g., by Liking someone’s post or
            content), we may use this information to personalize content in your
            feed, suggest that you follow certain members on our network, or
            suggest related content to help you towards that new interest. We
            use your content, activity and other data, including your name and
            photo, to provide notices to your network and others. For example,
            subject to your settings, we may notify others that you have updated
            your profile, posted content, took a social action, used a feature,
            made new connections or made progress on your journey towards
            achieving your goal.
          </p>
          <h2 className={styles.mainH1}>Social Networking</h2>
          <p className={styles.mainp2}>
            Our Services allow you to explore the Member directory, and seek
            out, and be found for, social networking and connection
            opportunities. Your profile can be found by those looking to help
            (for a Goal, Step or Need) or be helped by you. We will use your
            publicly posted data to recommend Goals or other Members, show you
            and others relevant contacts (e.g., those who have interest in,
            specialize in teaching others how to accomplish or have achieved
            similar Goals, Steps or Needs; work at a company, in an industry,
            function or location or have certain skills and connections). You
            can send “Friend” connection requests to the Members you are
            interested in or receive
          </p>
          <p className={styles.mainP4}>
            connection requests. We may use your data to recommend services or
            products to you and you. We may use automated systems to provide
            content and recommendations to help make our Services more relevant
            to our Members. Keeping your profile accurate and up-to-date may
            help you better connect to others and to opportunities through our
            Services.
          </p>
          <h2 className={styles.mainH1}>Productivity</h2>
          <p className={styles.mainp2}>
            Our Services allow you to collaborate with colleagues, search for
            potential clients, customers, partners and others to do business
            with. Our Services allow you to communicate with other Members and
            schedule meetings with them. If your settings allow, we scan
            messages to provide “bots” or similar tools that facilitate tasks
            such as scheduling meetings, drafting responses, paraphrasing and
            summarizing messages. Our Services also recommend or suggest new
            Goals, Steps, Needs, products or services that may help you on your
            journey to reaching your goals
          </p>
          <h2 className={styles.mainH1}>Communications</h2>
          <p className={styles.mainp2}>
            We contact you and enable communications between Members. We offer
            settings to control what messages you receive and how often you
            receive some types of messages.
          </p>
          <p className={styles.mainP4}>
            We will contact you through email, mobile phone messages (SMS), push
            notifications, notices posted on our website or in-app, or messages
            in-app through GoalMogul Chat messenger. We will send you messages
            about the availability of our Services, security, or other
            service-related issues. We also send messages about how to use our
            Services, network updates, reminders, Friend suggestions and
            recommended Goals, Steps, Needs, and promotional messages from us
            and our partners. You may change your communication preferences at
            any time. Please be aware that you cannot opt out of receiving
            service messages from us, including security and legal notices. We
            also enable communications between you and others through our
            Services, including for example invitations, groups and messages
            between connections.
          </p>
          <h2 className={styles.mainH1}>Advertising in the Future</h2>
          <p className={styles.mainp2}>
            We don’t currently advertise on our app now, but in the future,
            announcements and notices, along with an updated Privacy Policy,
            will be sent out to let you know if we begin to serve tailored ads
            to you both on and off our Services.
          </p>
          <p className={styles.mainP4}>
            Our website GoalMogul.com utilizes advertising technologies such as
            pixels, ad tags, cookies, and device identifiers.
          </p>
          <h2 className={styles.mainH1}>Ad Choices</h2>
          <p className={styles.mainp2}>
            We do not presently share your personal data with any third-party
            advertisers or ad networks.
          </p>
          <h2 className={styles.mainH1}>Marketing</h2>
          <p className={styles.mainp2}>
            We promote our Services to you and others. In addition to
            advertising our Services, we use Member’s data and content for
            invitations and communications promoting membership and network
            growth, engagement and our Services, such as by showing your
            connections that you have used a feature on our Services.
          </p>
          <h2 className={styles.mainH1}>Developing Services and Research</h2>
          <p className={styles.mainp2}>
            We develop our Services and conduct research.
          </p>
          <h2 className={styles.mainH1}>Service Development</h2>
          <p className={styles.mainp2}>
            We use data, including public feedback, to conduct research and
            development for our Services in order to provide you and others with
            a better, more intuitive and personalized experience, drive
            membership growth and engagement on our Services, and help connect
            Members to each other in the spirit of collaborative achievement.
          </p>
          <h2 className={styles.mainH1}>Surveys</h2>
          <p className={styles.mainp2}>
            Polls and surveys may be conducted by us and others through our
            Services. You are not obligated to respond to polls or surveys, and
            you have choices about the information you provide. You may opt-out
            of survey invitations.
          </p>
          <h2 className={styles.mainH1}>Customer Support</h2>
          <p className={styles.mainp2}>
            We use data to help you and fix problems. We use data (which can
            include your communications) to investigate, respond to and resolve
            complaints and for Service issues (e.g., bugs).
          </p>
          <h2 className={styles.mainH1}>Security and Investigations</h2>
          <p className={styles.mainp2}>
            We use data for security, fraud prevention and investigations. We
            use your data (including your communications) for security purposes
            or to prevent or investigate possible fraud or other violations of
            our User Agreement and/or attempts to harm our Members, Visitors or
            others.
          </p>
          <h2 className={styles.mainH2}>How We Share Information</h2>
          <h2 className={styles.mainH1}>Our Services</h2>
          <p className={styles.mainp2}>
            Any data that you include on your profile and any content you post
            publicly or social action (e.g., likes, follows, comments, shares)
            you take on our Services will be seen by others, consistent with
            your settings.
          </p>
          <h2 className={styles.mainH1}>Profile</h2>
          <p className={styles.mainp2}>
            Your profile is fully visible to all Members and customers of our
            Services. Subject to your settings, it can also be visible to others
            on or off of our Services (e.g., Visitors to our Services).
          </p>
          <h2 className={styles.mainH1}>
            Posts, Likes, Follows, Comments, Messages
          </h2>
          <p className={styles.mainp2}>
            Our Services allow viewing and sharing information including through
            posts, likes, follows and comments. When you share an article or a
            post (e.g., an update, image, video or article) publicly it can be
            viewed by everyone and re-shared anywhere (subject to your privacy
            settings). Members, Visitors and others will be able to find and see
            your publicly-shared content, including your name (and photo if you
            have provided one). In a group, posts are visible to others in the
            group. Your membership in groups is public and part of your profile,
            but you can change visibility in your settings.
          </p>
          <p className={styles.mainP4}>
            When you follow another Member, you are visible to others and that
            “page owner” as a follower. We let Members who make feed posts or
            send you Chat messages know when you act on their message, subject
            to your settings where applicable.
          </p>
          <p className={styles.mainP4}>
            Subject to your settings, we may let a Member know when you view
            their profile. When you like or re-share or comment on another’s
            content (including ads), others will be able to view these “social
            actions” and associate it with you (e.g., your name, profile and
            photo if you provided it).
          </p>
          <h2 className={styles.mainH1}>Other’s Services</h2>
          <p className={styles.mainp2}>
            You may link your account with other’s services so that you can
            import your information from other services into our Service. For
            example, you may link your LinkedIn or Clubhouse account to import
            your profile information from those services into your GoalMogul
            profile. Third-party services have their own privacy policies.
          </p>
          <p className={styles.mainP4}>
            Subject to your settings, excerpts from your profile will appear on
            the services of others (e.g., search engine results, mail and
            calendar applications that show a user limited profile data of the
            person they are meeting or messaging, social media aggregators,
            talent and lead managers). “Old” profile information remains on
            these services until they update their data cache with changes you
            made to your profile.
          </p>
          <h2 className={styles.mainH1}>Service Providers</h2>
          <p className={styles.mainp2}>
            We may use others to help us with our Services.
          </p>
          <p className={styles.mainp2}>
            We use others to help us provide our Services (e.g., maintenance,
            analysis, audit, fraud detection, marketing and development). They
            will have access to your information as reasonably necessary to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for other purposes.
          </p>
          <h2 className={styles.mainH1}>Legal Disclosures</h2>
          <p className={styles.mainp2}>
            We may need to share your data when we believe it’s required by law
            or to help protect the rights and safety of you, us or others. It is
            possible that we will need to disclose information about you when
            required by law, subpoena, or other legal process or if we have a
            good faith belief that disclosure is reasonably necessary to (1)
            investigate, prevent or take action regarding suspected or actual
            illegal activities or to assist government enforcement agencies; (2)
            enforce our agreements with you; (3) investigate and defend
            ourselves against any third-party claims or allegations; (4) protect
            the security or integrity of our Services (such as by sharing with
            companies facing similar threats); or (5) exercise or protect the
            rights and safety of GoalMogul, our Members, personnel or others. We
            attempt to notify Members about legal demands for their personal
            data when appropriate in our judgment, unless prohibited by law or
            court order or when the request is an emergency. We may dispute such
            demands when we believe, in our discretion, that the requests are
            overbroad, vague or lack proper authority, but we do not promise to
            challenge every demand.
          </p>
          <h2 className={styles.mainH1}>Change in Control or Sale</h2>
          <p className={styles.mainp2}>
            We may share your data if our business is sold to others, but it
            must continue to be used in accordance with this Privacy Policy. We
            can also share your personal data as part of a sale, merger or
            change in control, or in preparation for any of these events. Any
            other entity which buys us or part of our business will have the
            right to continue to use your data, but only in the manner set out
            in this Privacy Policy unless you agree otherwise.
          </p>
          <h2 className={styles.mainH2}>Your Choices & Obligations</h2>
          <h2 className={styles.mainH1}>Data Retention</h2>
          <p className={styles.mainp2}>
            We keep most of your personal data for as long as your account is
            open. We generally retain your personal data as long as you keep
            your account open or as needed to provide you Services. This
            includes data you or others provided to us and data generated or
            inferred from your use of our Services. Even if you only use our
            Services once every few years, we will retain your information and
            keep your profile open, unless you close your account. In some cases
            we may choose to retain certain information (e.g., insights about
            Services use) in a depersonalized or aggregated form.
          </p>
          <h2 className={styles.mainH1}>
            Rights to Access and Control Your Personal Data
          </h2>
          <p className={styles.mainp5}>
            You can access or delete your personal data. You have many choices
            about how your data is collected, used and shared.
          </p>
          <p className={styles.mainp5}>
            We provide many choices about the collection, use and sharing of
            your data, from deleting or correcting data you include in your
            profile and controlling the visibility of your posts to advertising
            opt-outs and communication controls. We offer you settings to
            control and manage the personal data we have about you.
          </p>
          <p className={styles.mainp5}>
            For personal data that we have about you, you can:
          </p>
          <p className={styles.mainp5}>
            Delete Data: You can ask us to erase or delete all or some of your
            personal data (e.g., if it is no longer necessary to provide
            Services to you).
          </p>
          <p className={styles.mainp5}>
            Change or Correct Data: You can edit some of your personal data
            through your account. You can also ask us to change, update or fix
            your data in certain cases, particularly if it’s inaccurate.
          </p>
          <p className={styles.mainp5}>
            Object to, or Limit or Restrict, Use of Data: You can ask us to stop
            using all or some of your personal data (e.g., if we have no legal
            right to keep using it) or to limit our use of it (e.g., if your
            personal data is inaccurate or unlawfully held).
          </p>
          <p className={styles.mainp5}>
            Right to Access and/or Take Your Data: You can ask us for a copy of
            your personal data and can ask for a copy of personal data you
            provided in machine readable form.
          </p>
          <p className={styles.mainp5}>
            Visitors can learn more about how to make these requests here. You
            may also contact us using the contact information below, and we will
            consider your request in accordance with applicable laws.
          </p>
          <p className={styles.mainp6}>
            Residents in the Designated Countries and other regions may have
            additional rights under their laws.
          </p>
          <h2 className={styles.mainH1}>Account Closure</h2>
          <p className={styles.mainp5}>
            We keep some of your data even after you close your account.
          </p>
          <p className={styles.mainp5}>
            If you choose to close your GoalMogul account, your personal data
            will generally stop being visible to others on our Services within
            24 hours. We generally delete closed account information within 30
            days of account closure, except as noted below.
          </p>
          <p className={styles.mainp5}>
            We retain your personal data even after you have closed your account
            if reasonably necessary to comply with our legal obligations
            (including law enforcement requests), meet regulatory requirements,
            resolve disputes, maintain security, prevent fraud and abuse (e.g.,
            if we have restricted your account for breach of our Terms of
            Service), enforce our User Agreement, or fulfill your request to
            "unsubscribe" from further messages from us. We will retain
            de-personalized information after your account has been closed.
          </p>
          <p className={styles.mainp6}>
            Information you have shared with others (e.g., through Chat
            messenger, updates or group posts) will remain visible after you
            close your account or delete the information from your own profile
            or mailbox, and we do not control data that other Members have
            copied out of our Services. Groups content associated with closed
            accounts will show an unknown user as the source. Your profile may
            continue to be displayed in the services of others (e.g., search
            engine results) until they refresh their cache.
          </p>
          <h2 className={styles.mainH2}>Other Important Information</h2>
          <h2 className={styles.mainH1}>Security</h2>
          <p className={styles.mainp2}>
            We monitor for and try to prevent security breaches. Please use the
            security features available through our Services. We implement
            security safeguards designed to protect your data, such as HTTPS. We
            regularly monitor our systems for possible vulnerabilities and
            attacks. However, we cannot warrant the security of any information
            that you send us. There is no guarantee that data may not be
            accessed, disclosed, altered, or destroyed by breach of any of our
            physical, technical, or managerial safeguards.
          </p>
          <h2 className={styles.mainH1}>Cross-Border Data Transfers</h2>
          <p className={styles.mainp2}>
            We store and use your data outside your country. We process data
            both inside and outside of the United States and rely on
            legally-provided mechanisms to lawfully transfer data across
            borders. Learn more. Countries where we process data may have laws
            which are different from, and potentially not as protective as, the
            laws of your own country.
          </p>
          <h2 className={styles.mainH1}>Lawful Bases for Processing</h2>
          <p className={styles.mainp5}>
            We have lawful bases to collect, use and share data about you. You
            have choices about our use of your data. At any time, you can
            withdraw consent you have provided by going to settings.
          </p>
          <p className={styles.mainp6}>
            We will only collect and process personal data about you where we
            have lawful bases. Lawful bases include consent(where you have given
            consent), contract (where processing is necessary for the
            performance of a contract with you (e.g., to deliver the GoalMogul
            Services you have requested) and “legitimate interests.” Learn more.
            Where we rely on your consent to process personal data, you have the
            right to withdraw or decline your consent at any time and where we
            rely on legitimate interests, you have the right to object. If you
            have any questions about the lawful bases upon which we collect and
            use your personal data, please contact us at{" "}
            <a href='mailto:dev@goalmogul.com'>dev@goalmogul.com</a>
          </p>
          <h2 className={styles.mainH1}>Contact Information</h2>
          <p className={styles.mainp5}>
            You can contact us or use other options to resolve any complaints.
          </p>
          <p className={styles.mainp6}>
            If you have questions or complaints regarding this Policy, please
            contact GoalMogul by emailing us at{" "}
            <a href='mailto:dev@goalmogul.com'>dev@goalmogul.com</a>
          </p>
        </div>
      </main>
    )
  }
}
