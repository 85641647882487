import React, { useEffect, useState } from "react"
import Brand from "../../assets/Logo.png"
import person_rounded from "../../assets/person_rounded.png"
import "../ReminderConfirmation.scss"
import { acceptReminder, getReminder } from "../../api"
import { Link } from "react-router-dom"
import defaultImage from "../../assets/images/default_profile_img_gray.png"
interface ReminderFrom {
  name: string
  email: string
  phone: string
  profile: {
    image: string
  }
  // other properties if applicable
}
interface recipient_contacts {}
const ReminderConfirmation: React.FC = () => {
  const [type, setType] = useState<string>("email")
  const [key, setKey] = useState<string>("")
  const [reminderFrom, setReminderFrom] = useState<ReminderFrom>({
    name: "",
    email: "",
    phone: "",
    profile: {
      image: "",
    },
  })
  useEffect(() => {
    const url = new URL(window.location.href)
    // let key = url.searchParams.get("key")
    let key = url.searchParams.get("key")
    setKey(key || "")
    setType(url.searchParams.get("type") || "email")
    getReminders(key || "")
  }, [])

  const getReminders = async (key: string) => {
    if (key) {
      console.log(key)
      const acceptResp = await acceptReminder(key)
      console.log(acceptResp)
      setReminderFrom(acceptResp?.data?.created_by)
    }
  }

  const getProfileImage = (image?: any, defaultImage?: any): any => {
    console.log(image, "image")
    if (!image) return defaultImage || "" // Return default image if no image is provided
    return image.includes("https:")
      ? image
      : `https://goalmogul-v1.s3-us-west-2.amazonaws.com/${image}`
  }

  useEffect(() => {
    console.log(reminderFrom, "reminderFrom")
  }, [reminderFrom])

  return (
    <div className='ExtraPages reminderConfirmataion'>
      <div className='App'>
        <div className='app-header'>
          <div className='header-content'>
            <img src={Brand} alt='brand' />
          </div>
        </div>
        {type === "sms" ? (
          <div className='box-content reminder-content'>
            <p className='heading'>
              Reminders by SMS <br />
              Powered by GoalMogul
            </p>
            <p className='text'>Thanks!</p>
            <p className='text'> You will now receive SMS Reminders from:</p>
            <div>
              <img
                src={getProfileImage(reminderFrom?.profile.image, defaultImage)}
                alt='person'
                width={45}
                height={45}
                style={{ borderRadius: "100px", border: "1px solid #42c0f5" }}
              />
            </div>
            <p className='text'>
              <span className='blue-text'>
                {reminderFrom?.name || ""}
                <br />
                {reminderFrom?.phone ? `<${reminderFrom?.phone || ""}>` : ""}
              </span>
            </p>
            <a href={`/reminder?key=${key}`} className='reminder-buttonLink'>
              <button className='reminder-button'>View Reminder</button>
            </a>
            <p className='text'>
              You can unsubscribe from SMS Reminders anytime by texting "STOP"
              to +1 (760) 607-2597
            </p>
          </div>
        ) : (
          <div className='box-content reminder-content'>
            <p className='heading'>
              Reminders by Email
              <br /> Powered by GoalMogul
            </p>
            <p className='text'>Thanks!</p>
            <p className='text'>
              You will now receive
              <br />
              Email Reminders from:
            </p>
            <div>
              <img
                src={getProfileImage(reminderFrom?.profile.image, defaultImage)}
                width={45}
                height={45}
                style={{ borderRadius: "100px", border: "1px solid #42c0f5" }}
                alt='person'
              />
            </div>
            <p className='text'>
              <span className='blue-text'>{reminderFrom?.name || ""}</span>{" "}
              {reminderFrom?.email ? `<${reminderFrom?.email || ""}>` : null}
            </p>
            <a href={`/reminder?key=${key}`} className='reminder-buttonLink'>
              <button className='reminder-button'>View Reminder</button>
            </a>
            <p className='text'>
              You can unsubscribe anytime by tapping the Unsubscribe button at
              the bottom of the emails.
            </p>
          </div>
        )}
        <div className='app-footer'>
          <p>GoalMogul Inc. © 2022</p>
        </div>
      </div>
    </div>
  )
}

export default ReminderConfirmation
